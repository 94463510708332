<template>
  <div id="transactionHistory">
    <div class="content-box">
      <div class="inner">
        <div class="content-header">
          <h3>{{ $t('menu.transactionHis') }}</h3>
          <div class="export-tool">
            <DateRangePicker
              :startDate="startDate"
              :endDate="endDate"
              :startDateOption="dateOption"
              :endDateOption="dateOption"
              @change="onPickerChange"
            />
            <el-button class="export-btn btn-red" @click="onExportClick">
              {{ $t('common.button.export') }}
            </el-button>
          </div>
        </div>
        <div class="top card">
          <div class="line">
            <ul class="tab-demo">
              <li :class="{ active: activeName === 'deposit' }" @click="activeName = 'deposit'" data-testid="deposit">
                {{ $t('transcationHistory.deposit') }}
              </li>
              <li
                :class="{ active: activeName === 'withdraw' }"
                @click="activeName = 'withdraw'"
                data-testid="withdraw"
              >
                {{ $t('transcationHistory.withdraw') }}
              </li>
              <li
                :class="{ active: activeName === 'transfer' }"
                @click="activeName = 'transfer'"
                data-testid="transfer"
              >
                {{ $t('menu.tranHis') }}
              </li>
            </ul>
            <ul class="table-demo">
              <li class="table-item" :class="{ active: activeName === 'deposit' }">
                <div class="table-box">
                  <el-table :data="depositTable" style="width: 100%">
                    <el-table-column prop="date" :label="$t('common.column.date')" min-width="120"></el-table-column>
                    <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
                      <template slot-scope="accountScope">
                        {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.method')" min-width="120">
                      <template slot-scope="methodScope">
                        <span>{{ showDepositMethod(methodScope.row.method, methodScope.row.channel) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.amt')" min-width="120">
                      <template slot-scope="amountScope">
                        {{ amountScope.row.currency | currencyToSymbol }}{{ amountScope.row.amount | formatNumber(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.status')" min-width="120">
                      <template slot-scope="statusScope">
                        {{ depositStatus[statusScope.row.status] | transactionStatus }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </li>
              <li class="table-item" :class="{ active: activeName === 'withdraw' }">
                <div class="table-box">
                  <el-table :data="withdrawTable" style="width: 100%">
                    <el-table-column prop="date" :label="$t('common.column.date')" min-width="120"></el-table-column>
                    <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
                      <template slot-scope="accountScope">
                        {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.method')" min-width="120">
                      <template slot-scope="methodScope">
                        <span>{{ showWithdrawMethod(methodScope.row.method, methodScope.row.transferCountry) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.amt')" width="232">
                      <template slot-scope="amountScope">
                        <p class="amount-text">
                          <span class="info-icon" @click="showDetails(amountScope, $event)"></span>
                          {{ amountScope.row.currency | currencyToSymbol }}
                          {{ amountScope.row.amount | formatNumber(2) }}
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.column.status')" min-width="120">
                      <template slot-scope="statusScope">
                        {{
                          getWithdrawStatus(
                            statusScope.row.status,
                            statusScope.row.processedByOp,
                            statusScope.row.reversedType
                          ) | transactionStatus
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="remark"
                      :label="$t('common.column.remark')"
                      min-width="120"
                    ></el-table-column>
                    <el-table-column min-width="120" v-if="showCancelWithdraw">
                      <template slot-scope="statusScope">
                        <el-button
                          v-if="showCancelButton(statusScope.row)"
                          @click="cancelWithdraw(statusScope.row)"
                          size="mini"
                          class="btn-blue cancel-btn"
                        >
                          {{ $t('common.button.cancel') }}
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </li>
              <li class="table-item" :class="{ active: activeName === 'transfer' }"><TransferHistory /></li>
            </ul>
          </div>

          <div class="middle">
            <!-- 分页 -->
            <div class="page-box clearfix" v-show="activeName === 'deposit'">
              <div class="el-pagination_box">
                <el-pagination
                  :background="true"
                  :page-size="25"
                  layout="total, sizes, prev, pager, next"
                  :total="depositTotal"
                  :page-sizes="[25, 50, 100]"
                  @current-change="handleDepositCurrentChange"
                  @size-change="handleDepositSizeChange"
                  :current-page.sync="depositPageNo"
                ></el-pagination>
              </div>
            </div>
            <!-- 分页 -->
            <div class="page-box clearfix" v-show="activeName === 'withdraw'">
              <div class="el-pagination_box">
                <el-pagination
                  :background="true"
                  :page-size="25"
                  layout="total, sizes, prev, pager, next"
                  :total="withdrawTotal"
                  :page-sizes="[25, 50, 100]"
                  @current-change="handleWithdrawCurrentChange"
                  @size-change="handleWithdrawSizeChange"
                  :current-page.sync="withdrawPageNo"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>

        <h3 class="sub-title" v-if="['deposit', 'withdraw'].includes(activeName)">
          {{ $t('common.field.statusDef') }}
        </h3>

        <div class="bottom">
          <!-- status -->
          <div class="status-box" v-show="activeName === 'deposit'">
            <ul>
              <li>
                <div class="center">
                  <div class="tag">{{ $t('common.field.incomplete') }}</div>
                </div>
                <p style="width: 90%">{{ $t('transcationHistory.desc.incomplete') }}</p>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.successful') }}</div>
                <p>{{ $t('transcationHistory.desc.success') }}</p>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.processing') }}</div>
                <i18n path="transcationHistory.desc.proc" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.failed') }}</div>
                <i18n path="transcationHistory.desc.failed" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.rejected') }}</div>
                <i18n path="transcationHistory.desc.rejected" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
            </ul>
          </div>
          <!-- status -->
          <div class="status-box" v-show="activeName === 'withdraw'">
            <ul>
              <li>
                <div class="tag">{{ $t('common.field.submitted') }}</div>
                <p>{{ $t('transcationHistory.withdrawDesc.sub') }}</p>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.successful') }}</div>
                <p>{{ $t('transcationHistory.withdrawDesc.suc') }}</p>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.partialsuccess') }}</div>
                <i18n path="transcationHistory.withdrawDesc.partialSucc" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL"> {{ GLOBAL_CONTACT_EMAIL }} </a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.processing') }}</div>
                <i18n path="transcationHistory.withdrawDesc.proc" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.incomplete') }}</div>
                <i18n path="transcationHistory.withdrawDesc.incomp" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.cancelled') }}</div>
                <i18n path="transcationHistory.withdrawDesc.cancel" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.failed') }}</div>
                <i18n path="transcationHistory.withdrawDesc.fail" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
              <li>
                <div class="tag">{{ $t('common.field.rejected') }}</div>
                <i18n path="transcationHistory.withdrawDesc.rej" tag="p">
                  <template v-slot:mailTo>
                    <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                  </template>
                </i18n>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <DialogVersion3 :visible.sync="dialogVisible">
      <div class="dialog-body">
        <el-form label-position="top" :model="form" ref="form" status-icon>
          <AccountNumber supportedCurrencies="all" @setAccountNumber="setAccountNumber"></AccountNumber>
          <div class="dialog-btn-box">
            <el-button class="btn-blue" @click="onConfirm" data-testid="ok">
              {{ $t('common.button.ok') }}
            </el-button>
            <el-button class="btn-default" @click="dialogVisible = false" data-testid="cancel">
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </DialogVersion3>

    <!-- cancel withdraw dialog -->
    <InteractiveDialog :show.sync="withdrawalCancelDialog" :text="$t('transcationHistory.cancelWithdraw')">
      <template v-slot:btn>
        <div class="withdraw-dialog-action">
          <el-button class="btn-default" plain @click="closeDialog" data-testid="no">
            {{ $t('common.field.no') }}
          </el-button>
          <el-button class="btn-blue" @click="submitCancelWithdraw" data-testid="yes">
            {{ $t('common.field.yes') }}
          </el-button>
        </div>
      </template>
    </InteractiveDialog>

    <!-- amount detail dialog -->
    <GeneralDialog
      :visible.sync="isShowDetailDialog"
      @close="closeDetailDialog"
      :extraClass="'amount-detail-dialog'"
      needFullscreen
    >
      <div class="amount-detail-content" v-cloak>
        <div class="title">{{ withdrawDialogLabel[withdrawDetail.withdrawalTypeCategory] }}</div>
        <div class="payment-icon-container">
          <img
            v-show="withdrawDetail.withdrawalType"
            class="payment-img"
            :class="[getWidthdrawalIconMap(withdrawDetail.withdrawalType)]"
            :src="
              withdrawDetail.withdrawalType
                ? require(`@/assets/images/withdraw/${getWidthdrawalIconMap(withdrawDetail.withdrawalType)}.png`)
                : ''
            "
            :alt="withdrawDetail.withdrawalType"
          />
          <div class="payment-title">{{ $t('transcationHistory.dialog.appliedAmount') }}</div>
          <div class="payment-amount">
            {{ withdrawDetail.accountCurrency | currencyToSymbol }}{{ withdrawDetail.appliedAmount }}
          </div>
        </div>
        <div class="payment-detail">
          <template v-for="(column, idx) in withdrawDialogOrders">
            <div
              :key="idx"
              class="item"
              v-if="withdrawDetail[column] || withdrawDetail[column] === 0 || withdrawDetail[column] === '0'"
            >
              <div class="payment-title">{{ $t(`transcationHistory.dialog.${column}`) }}</div>
              <div class="payment-text">
                {{ getWithdrawDialogContent(column) }}
              </div>
            </div>
          </template>

          <el-button v-if="withdrawDetail.bankStatement" class="btn-blue bank-statement-btn" @click="showBankStatement">
            {{ $t('transcationHistory.dialog.bankStatement') }}
          </el-button>
        </div>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import {
  apiTransactionHistory,
  apiDownloadAccountTransactionHistoryPDF,
  apiCancelWithdraw,
  apiTransactionHistoryDetail
} from '@/resource';
import TransferHistory from '@/components/transactions/TransferHistory';
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import AccountNumber from '@/components/form/AccountNumber';
import DateRangePicker from '@/components/DateRangePicker';
import moment from 'moment';
import depositHistory from '@/constants/payment/depositHistory';
import withdrawHistory from '@/constants/payment/withdrawHistory';

export default {
  name: 'TransactionHistory',
  components: { TransferHistory, DialogVersion3, InteractiveDialog, GeneralDialog, AccountNumber, DateRangePicker },
  filters: {
    formateDialogDate: value => {
      if (value) {
        return moment(value).format('DD-MM-YYYY, HH:mm:ss');
      }
      return '';
    }
  },
  data() {
    const defaultRange = 7;
    const exportEndDate = new Date();
    const exportStartDate = new Date(
      exportEndDate.getFullYear(),
      exportEndDate.getMonth(),
      exportEndDate.getDate() - defaultRange
    );
    return {
      startDate: exportStartDate,
      endDate: exportEndDate,
      form: {
        accountNumber: null
      },
      dialogVisible: false,
      dateOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateFormat: 'YYYY-MM-DD',
      depositPageNo: 1,
      depositPageSize: 25,
      depositUrl: 'deposit',
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      withdrawUrl: 'withdraw',
      depositTotal: 0,
      withdrawTotal: 0,
      depositTable: [],
      depositStatus: {
        1: 'Incomplete',
        2: 'Incomplete',
        3: 'Failed',
        4: 'Processing',
        5: 'Successful',
        6: 'Processing',
        7: 'Rejected',
        8: 'Processing',
        9: 'Processing',
        11: 'Successful'
      },
      withdrawTable: [],
      withdrawStatus: {
        // WITHDRAW_SUBMIT(1), // 提交中
        // WITHDRAW_CANCEL(2), // 取消
        // WITHDRAW_AUDIT(3), // 审核中
        // WITHDRAW_REFUSE(4), // 拒绝
        // WITHDRAW_ACCEPT(5), // 受理
        // WITHDRAW_PAYFAIL(6), // 打款失败
        // WITHDRAW_SUCCESS(7), // 出金成功
        // WITHDRAW_FAIL(8), // 出金失败
        // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
        // WITHDRAW_HALFFAILED(10); // 部分失败
        // WITHDRAW_REVERSE_FAILED(20,"Reverse processing");
        1: 'Submitted',
        2: 'Cancelled',
        3: 'Processing',
        4: 'Rejected',
        5: 'Processing',
        6: 'Failed',
        7: 'Successful',
        8: 'Failed',
        9: 'Processing',
        10: 'Failed',
        11: 'Failed',
        13: 'Processing',
        14: 'Processing',
        15: 'Processing',
        16: 'Successful',
        17: 'PartialSuccess',
        20: 'Processing'
      },
      withdrawReversedType: {
        // 1 Cancelled by client at accept
        // 2 Reversed by op at accept
        // 3 Reversed by op at complete
        1: 'Cancelled',
        2: 'Failed',
        3: 'Failed'
      },
      activeName: 'deposit',
      withdrawalCancelDialog: false,
      cancelInfo: null,
      ngaCountryCode: 5796,
      isShowDetailDialog: false,
      withdrawDetail: {},
      withdrawDialogOrders: [
        // control withdraw dialog column orders (data refer to withdrawDetail object)
        'applicationTime',
        'processedTime',
        'accountCurrency',
        'creditDeducted',
        'processedNote',
        'bankName',
        'bankAccountNumber',
        'abaSortCode',
        'swiftCode',
        'bankAddress',
        'accountName',
        'accountNumber',
        'cardholderName',
        'cardNumber',
        'cardExpiryDate',
        'walletAddress',
        'ewalletEmailAddress'
      ],
      withdrawDialogLabel: {
        1: this.$t('common.withdrawChannel.banktransfer'),
        2: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
        3: this.$t('common.withdrawChannel.creditcard'),
        4: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
        5: this.$t('common.withdrawChannel.E_WALLET')
      }
    };
  },
  computed: {
    showCancelWithdraw() {
      if (this.withdrawTable)
        return this.withdrawTable.find(
          // 20220811 UninoPay not support CancelWithdraw (method: 4)
          element =>
            !element.processedByOp &&
            (element.status === 1 || element.status === 3 || element.status === 5) &&
            element.method !== 4
        );
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  },
  mounted() {
    //init transaction history
    this.initTransactionHistory(1);
    this.initTransactionHistory(2);
  },
  methods: {
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.initTransactionHistory(2);
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.initTransactionHistory(2);
    },
    handleDepositCurrentChange(pageNo) {
      this.depositPageNo = pageNo;
      this.initTransactionHistory(1);
    },
    handleDepositSizeChange(size) {
      this.depositPageSize = size;
      this.initTransactionHistory(1);
    },
    queryTransactionHistory(url, pageNo, pageSize) {
      return apiTransactionHistory(url, {
        pageNo: pageNo,
        pageSize: pageSize
      });
    },
    initTransactionHistory(type) {
      switch (type) {
        case 1:
          this.queryTransactionHistory(this.depositUrl, this.depositPageNo, this.depositPageSize).then(resp => {
            if (resp.data.code === 0) {
              this.depositTotal = resp.data.data.total;
              this.depositTable = resp.data.data.depositHistory;
            }
          });
          break;
        case 2:
          this.queryTransactionHistory(this.withdrawUrl, this.withdrawPageNo, this.withdrawPageSize).then(resp => {
            if (resp.data.code === 0) {
              this.withdrawTotal = resp.data.data.total;
              this.withdrawTable = resp.data.data.withdrawHistory;
            }
          });
          break;
        default:
          break;
      }
    },
    showDepositMethod(method, channel) {
      if (!depositHistory[method]) return method;

      const depositMethods = depositHistory[method][channel]
        ? depositHistory[method][channel].value
        : depositHistory[method].default.value;
      return this.$options.filters.depositMethods(depositMethods);
    },
    showWithdrawMethod(method, transferCountry) {
      if (!withdrawHistory[method]) return method;

      const withdrawMethods = withdrawHistory[method][transferCountry]
        ? withdrawHistory[method][transferCountry]
        : withdrawHistory[method].default;
      return this.$options.filters.withdrawMethods(withdrawMethods);
    },
    onExportClick() {
      this.dialogVisible = true;
    },
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber;
    },
    onPickerChange(val) {
      this.startDate = val[0];
      this.endDate = val[1];
    },
    onConfirm() {
      const reqParam = {
        startDate: moment(this.startDate).format(this.dateFormat),
        endDate: moment(this.endDate).format(this.dateFormat),
        accountNumber: this.form.accountNumber.toString()
      };
      apiDownloadAccountTransactionHistoryPDF(reqParam)
        .then(resp => {
          if (resp.data.code == 0) {
            this.pdfHandler(resp.data.data, reqParam);
          } else {
            this.errorMessage(this.$t('responseMsg.500'));
            this.dialogVisible = false;
          }
        })
        .catch(err => {
          this.errorMessage(this.$t('responseMsg.500'));
          this.dialogVisible = false;
        });
    },
    errorMessage(message) {
      this.$message({
        message: message,
        type: 'error'
      });
    },
    pdfHandler(data, reqParam) {
      const { startDate, endDate, accountNumber } = reqParam;
      const filename = `${accountNumber} ${startDate} to ${endDate}.pdf`;
      const bufferArray = this.base64ToArrayBuffer(data);
      const blob = new Blob([bufferArray], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const linkId = 'export_file';
      link.setAttribute('id', linkId);
      link.setAttribute('href', objectUrl);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.getElementById(linkId).remove();
      this.dialogVisible = false;
    },
    base64ToArrayBuffer(data) {
      const bString = window.atob(data);
      const bLength = bString.length;
      let bytes = new Uint8Array(bLength);
      for (let i = 0; i < bLength; i++) {
        bytes[i] = bString.charCodeAt(i);
      }
      return bytes;
    },
    showCancelButton(item) {
      // 20220811 UninoPay not support CancelWithdraw (method: 4)
      const { status, processedByOp, method } = item;
      return !processedByOp && (status === 1 || status === 3 || status === 5) && method !== 4;
    },
    cancelWithdraw(info) {
      this.withdrawalCancelDialog = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.withdrawalCancelDialog = false;
      this.cancelInfo = null;
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.initTransactionHistory(2);
          } else {
            this.showErrorMsg();
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.$message({
        message: this.$t('transcationHistory.cancelError', {
          supportEmail: this.GLOBAL_CONTACT_EMAIL
        }),
        type: 'error',
        duration: 10000
      });

      this.closeDialog();
      this.initTransactionHistory(2);
    },
    getWithdrawStatus(status, processedByOp, reversedType) {
      if (reversedType && reversedType !== 5 && reversedType !== 6) return this.withdrawReversedType[reversedType];
      return !processedByOp && (status === 3 || status === 5) ? this.withdrawStatus[1] : this.withdrawStatus[status];
    },
    async showDetails(item, event) {
      try {
        const { data } = await apiTransactionHistoryDetail(item.row.id, item.row.type);
        if (data.code === 0) {
          this.withdrawDetail = { ...data.data };
          this.isShowDetailDialog = true;
          event.target.classList.add('clicked');
        }
      } catch (error) {
        this.errorMessage(error);
      }
    },
    showBankStatement() {
      if (this.withdrawDetail.bankStatement) {
        window.open(`${location.origin}${this.withdrawDetail.bankStatement}`, '_blank');
      }
    },
    getWidthdrawalIconMap(value) {
      switch (value) {
        case 1:
          return `visa_icon`;
        case 2:
          return `swift_icon`;
        case 34:
          return `bitcoin_icon`;
        case 36:
        case 80:
          return `usdt_icon`;
        case 4:
        case 5:
        case 6:
        case 8:
        case 9:
        case 24:
        case 35:
        case 39:
        case 40:
        case 42:
        case 47:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
        case 56:
        case 57:
        case 58:
        case 60:
        case 62:
        case 63:
        case 64:
        case 65:
        case 66:
        case 67:
        case 68:
        case 110:
          return `vietna_icon`;
        case 7:
          return `fasapay_icon`;
        case 31:
          return `skrill_icon`;
        case 32:
          return `neteller_icon`;
        case 37:
          return `bitwallet_icon`;
        case 38:
          return `sticpay_icon`;
        case 41:
          return `astropay_icon`;
        case 61:
          return `perfect_money_icon`;
        default:
          return;
      }
    },
    closeDetailDialog() {
      this.isShowDetailDialog = false;
      this.withdrawDetail = {};
    },
    getWithdrawDialogContent(column) {
      switch (column) {
        case 'applicationTime':
          return this.$options.filters.formateDialogDate(this.withdrawDetail.applicationTime);
        case 'processedTime':
          return this.$options.filters.formateDialogDate(this.withdrawDetail.processedTime);
        case 'accountCurrency':
          return this.$options.filters.currencyToSymbol(this.withdrawDetail.accountCurrency);
        case 'creditDeducted':
          return `${this.$options.filters.currencyToSymbol(this.withdrawDetail.currencyToSymbol)}${
            this.withdrawDetail.creditDeducted
          }`;
        default:
          return this.withdrawDetail[column];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
