<template>
  <div class="calendar">
    <div class="calendar">
      <el-date-picker
        v-model="startDateValue"
        type="date"
        :clearable="false"
        :editable="false"
        prefix-icon="calendar_icon"
        :picker-options="startDateOption"
        @change="onPickerChange"
      >
      </el-date-picker>
      <div class="calendar-to">-</div>
      <el-date-picker
        v-model="endDateValue"
        type="date"
        :clearable="false"
        :editable="false"
        prefix-icon="calendar_icon"
        :picker-options="endDateOption"
        @change="onPickerChange"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    startDate: {
      type: Date,
      default: function() {
        return new Date();
      }
    },
    endDate: {
      type: Date,
      default: function() {
        return new Date();
      }
    },
    startDateOption: {
      type: Object,
      default: function() {
        return {};
      }
    },
    endDateOption: {
      type: Object,
      default: function() {
        return {};
      }
    },
    seperator: {
      type: String,
      default: '-'
    }
  },
  data() {
    return {
      startDateValue: this.startDate,
      endDateValue: this.endDate
    };
  },
  watch: {
    startDate(newVal) {
      this.startDateValue = newVal;
    },
    endDate(newVal) {
      this.endDateValue = newVal;
    }
  },
  methods: {
    convertUnix(date) {
      return parseInt(moment(new Date(date)).format('x'));
    },
    onPickerChange() {
      const dates = [this.startDateValue, this.endDateValue];
      if (this.convertUnix(dates[0]) > this.convertUnix(dates[1])) {
        this.startDateValue = dates[1];
        this.endDateValue = dates[0];
      }
      this.$emit('change', [this.startDateValue, this.endDateValue]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dateRangePicker.scss';
</style>
