<template>
  <div id="transferHistory">
    <div class="table-box">
      <el-table :data="displayData" style="width: 100%;">
        <el-table-column prop="date" :label="$t('common.column.date')" min-width="120">
          <template slot-scope="scope">{{ scope.row.date | dateFormat }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.key.from')" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.fromAccount + ' (' + scope.row.from_currency + ')' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.key.to')" min-width="120">
          <template slot-scope="scope">{{ scope.row.toAccount + ' (' + scope.row.to_currency + ')' }}</template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('common.column.amt')" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.from_currency === scope.row.to_currency">
              {{ scope.row.amount | formatNumber }}
            </span>
            <span v-else
              >{{ scope.row.from_currency | currencyToSymbol }}{{ scope.row.amount | formatNumber }}->{{
                scope.row.to_currency | currencyToSymbol
              }}{{ scope.row.toAmount | formatNumber }}</span
            >
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="120">
          <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
        </el-table-column>
        <el-table-column prop="notes" :label="$t('common.column.procNote')" min-width="120"> </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <div class="el-pagination_box">
        <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { apiTransferHistoryGet } from '@/resource';
export default {
  components: { Pagination },
  data() {
    return {
      userId: this.$store.state.common.uuid,
      tableData: [],
      displayData: []
    };
  },
  mounted() {
    apiTransferHistoryGet({ userId: this.userId }).then(response => {
      this.tableData = response.data;
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/transactions/transferHistory.scss';
</style>
